/* Media Query */

/* XXL */
@media (min-width: 1401px)
{
    
}

/* XL */
@media (min-width: 1201px) and (max-width:1400px)
{

}

/* LG */
@media (min-width: 993px) and (max-width:1200px)
{

}

/* MD */
@media (min-width: 768px) and (max-width:992px)
{
    .Form
    {
        position: absolute;
        z-index: 9;
        width: 100%;
        left: 0;
        right: 0;
    }

    .Cover
    {
        position: absolute;
        z-index: -9;
        width: 100%;
    }  

    .FormLogin
    {
        background-color: #ffffff;
    }
}

/* SM */
@media (min-width: 577px) and (max-width: 767px)
{
    .Logotipo
    {
        width: 200px;
        top: 40px;
    }

    .Form
    {
        position: absolute;
        z-index: 9;
        width: 100%;
        left: 0;
        right: 0;
    }

    .Cover
    {
        position: absolute;
        z-index: -9;
        width: 100%;
    }  

    .FormLogin
    {
        background-color: #ffffff;
    }
}

/* XS */
@media (max-width: 576px)
{
    .Logotipo
    {
        width: 180px;
        top: 45px;
    }

    .Form
    {
        position: absolute;
        z-index: 9;
        width: 100%;
        left: 0;
        right: 0;
    }

    .Cover
    {
        position: absolute;
        z-index: -9;
        width: 100%;
    }   

    .Cover p
    {
        text-align: center;
        right: 0;
        left: 0;
    }

    .FormLogin
    {
        background-color: #ffffff;
    }
}