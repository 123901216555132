@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*
{
    font-family: 'Poppins', sans-serif;
}

html, body
{
    margin: 0px;
    background-color: #f8f8f8!important;
}

.text-right
{
    text-align: right;
}

.bodyPage h4
{
    font-size: 38px;
    font-weight: 800;
    color: #505050;
}

.searchBar
{
    background: none;
    border: none;
}

/* Login */
.Logotipo
{
    width: 200px;
    top: 30px;
    margin-left:auto;
    margin-right:auto;
    left:0;
    right:0;
    position: absolute;
}

.FormLogin h2
{
    font-size: 32px;
    font-weight: 700;
    color: #939393;
    font-family: 'Poppins', sans-serif;
}

.FormLogin p
{
    font-size: 14px;
    color: #6e6e6e;
}

.Form
{
    height: 100%;
    position: relative;
}

.FormLogin
{
    padding: 20px;
}

.form-floating>.form-control:not(:placeholder-shown)~label::after
{
    background-color: transparent
}

.Send
{
    text-align: right;
}

.btn-login
{
    background-color: #009cff!important;
    padding: 5px 20px!important;
    border: none;
}

.Cover
{
    width: 100%;
    height: 100vh;
}

.LoginCover
{
    background: url(../Assets/cover.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
}

.Cover p
{
    position: absolute;
    bottom: 5px;
    right: 25px;
    color: #ffffff;
    font-size: 12px;
}

/* Sidebar */
.LeftSide
{
    width: 100%;
    height: 100vh;
    padding: 20px 0px;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0px 0px 30px 0px #0000000f;
    position: relative;
}

.LogotipoSide
{
    width: 180px;
    margin-top: 10px;
}

.LogotipoTop
{
    width: 170px;
    margin-top: 10px;
    margin-right: 15px;
}

.LogotipoMini
{
    width: 52px;
}

.LeftSide svg
{
    margin-top: -4px;
}

.Logout
{
    position: absolute;
    bottom: 20px;
    left: 0px;
    right: 0px;
    margin: 0px 20px;
}

.LogoutMini
{
    position: absolute;
    bottom: 20px;
    left: 0px;
    right: 0px;
    margin: 0px 20px;
}

.LogoutMini a
{
    font-size: 18px;
}

.BtnToggle
{
    position: absolute;
    right: -32px;
    bottom: 50px;
}

.BtnToggle button
{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 10px 5px 40px 0px #00000023;
}

/* Area principal */
.bodyPage
{
    margin: 30px 10px;
}

/* Table */

.table-responsive
{
    margin-top: 20px;
    font-size: 14px;
}

.table-responsive thead
{
    font-size: 15px;
}

.table-responsive thead tr th
{
    padding: 7px 5px;
}

.table-responsive tbody tr td
{
    padding: 6px 5px;
}

td.table-picture
{
    width: 60px;
}

.figure
{
    margin: 6px;
}

.figure img
{
    margin: 0px;
    border-radius: 15px;
    border: 1px solid #b5b5b5;
}

.UpdateBtn button
{
    white-space: pre;
}

/* Paginación */
.Pagination
{
    width: fit-content;
    margin: 16px 0px 0px 0px;
}

.page-link
{
    color: #34593e!important;
}

/* Employees */

.PicEdit
{
    width: 220px;
    height: 220px;
    border: 5px solid #b5b5b5;
}

.PicInfo
{
    width: 40px;
    float: left;
    margin-right: 5px;
    border: 2px solid #b5b5b5;
}

.UploadPic
{
    margin-top: 40px;
}

.UploadPic label
{
    border: 2px dashed #198754;
}

.UploadPic input[type=file]
{
    width: 100%;
}

.EmployeeDetails, .CompanyDetails
{
    margin-top: 20px;
    border: none!important;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 0px 0px 30px 0px #0000000f;
    padding: 15px;
}

.EmployeeDetails p, .CompanyDetails p
{
    margin: 2px 0px;
    font-size: 14px;
}

.CompanyDetails2 ol
{
    padding: 0px;
}

.CompanyDetails2 ol li
{
    padding-left: 5px;
}

.CompanyDetails2 .fw-bold
{
    font-weight: 600!important;
    font-size: 14px;
    margin-bottom: -4px;
}

.CompanyDetails2 span
{
    font-size: 12px;
    color: #6f6f6f;
}

.CompanyDetails2 p
{
    margin: 0px;
    font-size: 10px;
    color: #ffffff;
}

.Notice
{
    font-size: 12px;
    padding: 10px!important;
    margin-top: 15px;
}

.CompanyNames
{
    font-weight: 600;
    font-size: 14px;
    padding: 15px 5px!important;
}

.fs-14
{
    font-size: 14px;
}


/* Messages */

.MissInfo
{
    font-weight: 400;
    font-size: 14px;
    color: rgb(106, 106, 106);
    border-radius: 5px;
    width: fit-content;
    padding: 8px;
    border: 1px solid rgb(164, 164, 164);
    margin: 30px auto;
}